/* Atualizações na barra de navegação */
.navbar {
  background-color: #4c0bce !important;
  color: #fff !important;
  width: 100%;
}

.navbar a {
  color: #fff !important;
}

.navbar a:hover {
  color: #d1c4e9 !important; /* Ajuste para hover */
}

/* Background de todas as páginas */
body {
  background-color: #212529 !important;
  color: #fff !important;
  font-family: 'Roboto Condensed', sans-serif;
}

/* Containers */
.container, .card, .list-group-item {
  background-color: #1b1f22 !important;
  color: #fff !important;
  border: none; /* Remove as bordas para um visual mais limpo */
}

/* Botões */
.btn-outline-primary, .btn-outline-secondary, .btn-outline-success, .btn-outline-danger {
  color: #4c0bce !important;
  border-color: #4c0bce !important;
}

.btn-outline-primary:hover, .btn-outline-secondary:hover, .btn-outline-success:hover, .btn-outline-danger:hover {
  background-color: #4c0bce !important;
  color: #fff !important;
}

/* Ajuste para exibição dos perfis */
.profile-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  gap: 10px;
}

.card {
  margin: 5px;
  text-align: center;
  background-color: #1b1f22;
  border: none;
}

.photo-container {
  position: relative;
  width: 100%;
  padding-bottom: 133.33%; /* Proporção 3:4 */
  overflow: hidden;
  background-color: #000; /* Cor de fundo para visualizações de imagem */
}

.card-img-top {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  cursor: pointer;
}

/* Indicador de idade */
.age-indicator {
  position: absolute;
  top: 10px;
  right: 10px;
  padding: 5px;
  border-radius: 50%;
  font-size: 0.8rem;
  color: #fff;
  background-color: rgba(0, 0, 0, 0.7);
}

.age-18 {
  background-color: rgba(0, 128, 0, 0.7); /* Verde para maiores de 18 */
}

.age-17 {
  background-color: rgba(255, 0, 0, 0.7); /* Vermelho para menores de 18 */
}

.card-title {
  font-size: 1rem;
  margin-top: 5px;
  word-wrap: break-word;
  hyphens: auto;
}

.card-text {
  font-size: 0.8rem;
}

/* Adicionais para responsividade */
@media (max-width: 767.98px) {
  .profile-grid {
    grid-template-columns: 1fr;
  }

  .navbar-nav {
    flex-direction: column;
  }

  .card-title {
    font-size: 0.9rem;
  }
}

.container-outer {
  background-color: #1b1f22;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  font-family: 'Roboto Condensed', sans-serif;
  color: #fff;
  margin: 20px auto;
  max-width: 1200px;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.container-inner {
  display: flex;
  justify-content: space-around;
  margin-bottom: 20px;
}

.content-box {
  background-color: #262a2e;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  flex: 1;
  margin: 10px;
}

.list-group-item {
  background-color: #1b1f22;
  color: #fff;
  border: none;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.photo-main {
  width: 100%;
  height: auto;
  object-fit: cover;
}

.form-control {
  background-color: #1b1f22;
  color: #fff;
  border: 1px solid #4c0bce;
}

.btn-outline-secondary, .btn-outline-success, .btn-outline-danger {
  border: 2px solid #4c0bce;
  color: #4c0bce;
  background-color: transparent;
  transition: background-color 0.3s, color 0.3s;
}

.btn-outline-secondary:hover, .btn-outline-success:hover, .btn-outline-danger:hover {
  background-color: #4c0bce;
  color: #fff;
}

.photo-gallery {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.photo-item {
  position: relative;
  margin-bottom: 10px;
}

.photo-additional {
  width: 150px;
  height: 150px;
  object-fit: cover;
}

.btn-set-main {
  position: absolute;
  top: 10px;
  left: 10px;
  background-color: transparent;
  border: none;
  color: #fff;
  cursor: pointer;
}

/* Indicador de foto principal */
.main-photo-indicator {
  position: absolute;
  top: 10px;
  left: 10px;
  background-color: #4c0bce;
  color: #fff;
  padding: 5px;
  border-radius: 50%;
}

/* Botão de exclusão de foto */
.btn-delete-photo {
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: transparent;
  border: none;
  color: #ff4d4d;
  cursor: pointer;
}

.photo-viewer {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 1000;
}

.photo-viewer img {
  max-width: 90%;
  max-height: 90%;
  object-fit: contain;
}

@media (max-width: 767.98px) {
  .container-inner {
    flex-direction: column;
  }
}

/* Estilo para o indicador de status */
.status-indicator {
  position: absolute;
  bottom: 10px;
  left: 10px;
  display: flex;
  gap: 5px;
}

.status-indicator .badge {
  font-size: 0.8rem;
  padding: 5px;
}

.container-inner {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.content-box {
  background-color: #262a2e;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  flex: 1;
  margin: 10px;
}

/* Containers principais */
.container, .card, .list-group-item, .container-outer {
  background-color: #1b1f22 !important;
  color: #fff !important;
  border: none; /* Remove as bordas para um visual mais limpo */
}

.container-outer {
  background-color: #1b1f22;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  font-family: 'Roboto Condensed', sans-serif;
  color: #fff;
  margin: 20px auto;
  max-width: 1200px;
}

/* Responsividade para dispositivos móveis */
@media (max-width: 767.98px) {
  .profile-grid {
    grid-template-columns: 1fr;
  }

  .card-title {
    font-size: 0.9rem;
  }
}

/* Botões */
.btn-outline-secondary, .btn-outline-success, .btn-outline-danger {
  border: 2px solid #4c0bce;
  color: #4c0bce;
  background-color: transparent;
  transition: background-color 0.3s, color 0.3s;
}

.btn-outline-secondary:hover, .btn-outline-success:hover, .btn-outline-danger:hover {
  background-color: #4c0bce;
  color: #fff;
}

/* Importação da fonte Roboto Condensed */
@import url('https://fonts.googleapis.com/css2?family=Roboto+Condensed:wght@400;700&display=swap');

/* Estilo para aplicar a fonte Roboto Condensed */
.roboto-condensed {
  font-family: 'Roboto Condensed', sans-serif;
  font-optical-sizing: auto;
}
