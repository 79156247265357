:root {
    --docsearch-container-background: rgba(9, 10, 17, .8);
    --docsearch-modal-background: #15172a;
    --docsearch-modal-shadow: inset 1px 1px 0 0 #2c2e40, 0 3px 8px 0 #000309;
    --docsearch-searchbox-background: #090a11;
    --docsearch-searchbox-focus-background: #000;
    --docsearch-hit-color: #bec3c9;
    --docsearch-hit-shadow: none;
    --docsearch-hit-background: #090a11;
    --docsearch-key-gradient: linear-gradient(-26.5deg, #565872, #31355b);
    --docsearch-key-shadow: inset 0 -2px 0 0 #282d55, inset 0 0 1px 1px #51577d, 0 2px 2px 0 rgba(3, 4, 9, .3);
    --docsearch-footer-background: #1e2136;
    --docsearch-footer-shadow: inset 0 1px 0 0 rgba(73, 76, 106, .5), 0 -4px 8px 0 rgba(0, 0, 0, .2);
    --docsearch-muted-color: #7f8497;
  }
  
  [data-bs-theme="dark"] {
    --bd-violet: #9461fb;
    --bd-violet-bg: #712cf9;
    --bd-toc-color: var(--bs-emphasis-color);
    --bd-sidebar-link-bg: rgba(84, 33, 187, .5);
    --bd-callout-link: 110, 168, 254;
    --bd-callout-code-color: #e685b5;
    --bd-pre-bg: #1b1f22;
  }
  
  :root, [data-bs-theme="light"] {
    --bd-purple: #4c0bce;
    --bd-violet: #712cf9;
    --bd-accent: #ffe484;
    --bd-violet-rgb: 112.520718, 44.062154, 249.437846;
    --bd-accent-rgb: 255, 228, 132;
    --bd-pink-rgb: 214, 51, 132;
    --bd-teal-rgb: 32, 201, 151;
    --bd-violet-bg: var(--bd-violet);
    --bd-toc-color: var(--bd-violet);
    --bd-sidebar-link-bg: rgba(var(--bd-violet-rgb), .1);
    --bd-callout-link: 10, 88, 202;
    --bd-callout-code-color: #ab296a;
    --bd-pre-bg: var(--bs-tertiary-bg);
  }
  
  
  :root, [data-bs-theme="color01"] {
    --bd-purple: #4c0bce;
    --bd-violet: #712cf9;
    --bd-accent: #ffe484;
    --bd-violet-rgb: 112.520718, 44.062154, 249.437846;
    --bd-accent-rgb: 255, 228, 132;
    --bd-pink-rgb: 214, 51, 132;
    --bd-teal-rgb: 32, 201, 151;
    --bd-violet-bg: var(--bd-violet);
    --bd-toc-color: var(--bd-violet);
    --bd-sidebar-link-bg: rgba(var(--bd-violet-rgb), .1);
    --bd-callout-link: 10, 88, 202;
    --bd-callout-code-color: #ab296a;
    --bd-pre-bg: var(--bs-tertiary-bg);
  }
  
  
  
  body {
    color-scheme: dark;
    --bs-body-color: #dee2e6;
    --bs-body-color-rgb: 222, 226, 230;
    --bs-body-bg: #212529;
    --bs-body-bg-rgb: 33, 37, 41;
    --bs-emphasis-color: #fff;
    --bs-emphasis-color-rgb: 255, 255, 255;
    --bs-secondary-color: rgba(222, 226, 230, 0.75);
    --bs-secondary-color-rgb: 222, 226, 230;
    --bs-secondary-bg: #343a40;
    --bs-secondary-bg-rgb: 52, 58, 64;
    --bs-tertiary-color: rgba(222, 226, 230, 0.5);
    --bs-tertiary-color-rgb: 222, 226, 230;
    --bs-tertiary-bg: #2b3035;
    --bs-tertiary-bg-rgb: 43, 48, 53;
    --bs-primary-text-emphasis: #6ea8fe;
    --bs-secondary-text-emphasis: #a7acb1;
    --bs-success-text-emphasis: #75b798;
    --bs-info-text-emphasis: #6edff6;
    --bs-warning-text-emphasis: #ffda6a;
    --bs-danger-text-emphasis: #ea868f;
    --bs-light-text-emphasis: #f8f9fa;
    --bs-dark-text-emphasis: #dee2e6;
    --bs-primary-bg-subtle: #031633;
    --bs-secondary-bg-subtle: #161719;
    --bs-success-bg-subtle: #051b11;
    --bs-info-bg-subtle: #032830;
    --bs-warning-bg-subtle: #332701;
    --bs-danger-bg-subtle: #2c0b0e;
    --bs-light-bg-subtle: #343a40;
    --bs-dark-bg-subtle: #1a1d20;
    --bs-primary-border-subtle: #084298;
    --bs-secondary-border-subtle: #41464b;
    --bs-success-border-subtle: #0f5132;
    --bs-info-border-subtle: #087990;
    --bs-warning-border-subtle: #997404;
    --bs-danger-border-subtle: #842029;
    --bs-light-border-subtle: #495057;
    --bs-dark-border-subtle: #343a40;
    --bs-heading-color: inherit;
    --bs-link-color: #6ea8fe;
    --bs-link-hover-color: #8bb9fe;
    --bs-link-color-rgb: 110, 168, 254;
    --bs-link-hover-color-rgb: 139, 185, 254;
    --bs-code-color: #e685b5;
    --bs-highlight-color: #dee2e6;
    --bs-highlight-bg: #664d03;
    --bs-border-color: #495057;
    --bs-border-color-translucent: rgba(255, 255, 255, 0.15);
    --bs-form-valid-color: #75b798;
  
    /* Adiciona suporte para ajuste de tamanho de texto */
    text-size-adjust: 50%;
  }
  
  .btn-outline-primary {
    color: var(--bs-primary);
    border-color: var(--bs-primary);
  }
  
  .btn-outline-secondary {
    color: var(--bs-secondary);
    border-color: var(--bs-secondary);
  }
  
  .btn-outline-success {
    color: var(--bs-success);
    border-color: var(--bs-success);
  }
  
  .btn-outline-danger {
    color: var(--bs-danger);
    border-color: var(--bs-danger);
  }
  
  .btn-outline-warning {
    color: var(--bs-warning);
    border-color: var(--bs-warning);
  }
  
  .btn-outline-info {
    color: var(--bs-info);
    border-color: var(--bs-info);
  }
  
  .btn-outline-light {
    color: var(--bs-light);
    border-color: var(--bs-light);
  }
  
  .btn-outline-dark {
    color: var(--bs-dark);
    border-color: var(--bs-dark);
  }
  
  .placeholder-wave {
    mask-image: linear-gradient(130deg,#000 55%,rgba(0,0,0,0.8) 75%,#000 95%);
    -webkit-mask-image: linear-gradient(130deg,#000 55%,rgba(0,0,0,0.8) 75%,#000 95%);
    mask-size: 200% 100%;
    -webkit-mask-size: 200% 100%;
  }
  
  @keyframes placeholder-wave {
    100% {
      mask-position: -200% 0%;
      -webkit-mask-position: -200% 0%;
    }
  }
  
  /* Adiciona suporte para alinhamento de texto */
  th {
    text-align: -webkit-match-parent;
    text-align: match-parent;
  }